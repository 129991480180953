.login-container{
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
    align-items: center;
    justify-content: center;
}

.login-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-container label {
    display: flex;
    font-size: 18px;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    gap: 10px;
}

.login-container input {
    padding: 10px;
    border-radius: 4px;
}




.login-buttons button{
    display: flex;
    background-color: rgb(197, 197, 197);
    color: black;
    border: transparent;
    padding:15px;
    bottom:  0;
    width: 100%;
    margin-bottom: 10px;

}

.login-buttons button:hover{
    cursor: pointer;
    background-color: rgb(177, 177, 177);
}
