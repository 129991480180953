.deptlist-container{
    margin-left: 210px;
}

.deptlist-container h1{
    border-bottom: 3px solid darkgray;
    justify-content: space-between;
    display: flex;
    padding: 10px;
}

.deptlist-container button{
    margin-right: 0;
}

.title-container button{
    padding: 5px;
    margin-left: 15px;
}
