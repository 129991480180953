.dashboard-container{
    margin-left: 210px;
}

.nav-bar button{
    height: 30px;
    display: flex;
    background-color: rgb(197, 197, 197);
    color: black;
    border: 5px transparent;
    height: auto;
    padding: 15px;
}

.nav-bar button:hover{
    cursor: pointer;
    background-color: rgb(177, 177, 177);
}

.dashboard-container h1{
    border-bottom: 3px solid darkgray;
    justify-content: space-between;
    display: flex;
    padding: 5px;
}
