.job-container h2{
    border-bottom: 1px solid lightgray;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.job-container h2:hover{
    cursor: pointer;
}
.job-container{
    margin: none;
}

.job-container button{
    background-color: rgb(197, 197, 197);
    color: black;
    border: transparent;
    padding: 15px;
    margin: 10px;
}

.job-container button:hover{
    cursor: pointer;
    background-color: rgb(177, 177, 177);
}
