#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 100vh;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
  overflow: auto;
  border-radius: 4px;
}
