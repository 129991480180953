.splash-header{
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
}

.splash-header h1{
    display: flex;
    justify-content: space-around;
}

.splash-header h1 img {
    width: 35px;
    height: auto;
    display: flex;
    padding-right: 30px;
  }
.splash-body p{
    display: flex;
    align-items: center;
    font-size: 25px;
    text-align: center;

}

.splash-header button{
    background-color: rgb(197, 197, 197);
    color: black;
    border: transparent;
    padding: 15px;
    margin: 10px;
}

.splash-header button:hover{
    cursor: pointer;
    background-color: rgb(177, 177, 177);
}
